import { type Config } from 'tailwindcss'

const UpGreen = '#00C851';
const DownRed = '#FF3B30';
const primaryPurple = '#9F40B4';

export const extendedTheme = {
  fontFamily: {
    rounded: '"M PLUS Rounded 1c", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  },

  fontWeight: {
    thin: '100',
    normal: '400',
    bold: '700',
    black: '900',
  },

  colors: {
    primary: {
      100: '#0D0221', // Deep Purple/Blue (background)
      200: '#240041', // Slightly lighter for secondary backgrounds
      300: '#5C2E91', // Bright Purple (interactive elements)
    },
    accent: {
      yellow: '#FFB300', // Bright Yellow/Gold (highlights)
      red: DownRed, // Red (DOWN indicators)
      green: UpGreen, // Green (UP indicators)
      purple: primaryPurple,
      alert: '#FF7043', // Orange tone for warnings or alerts
    },
    text: {
      100: '#FFFFFF', // Main text color (white)
      200: '#B0B3B8', // Secondary text color (light grey)
    },
    background: {
      100: '#0D0221', // Main background (deep purple/blue)
      200: '#240041', // Secondary background
      300: '#2C0E37', // Tertiary background (even lighter purple)
    },
  },
  backgroundColor: {
    green: UpGreen,
    red: DownRed,
  },
  borderColor: {
    green: UpGreen,
    red: DownRed,
  },
  borderRadius: {
    lg: 'var(--radius)',
    md: 'calc(var(--radius) - 2px)',
    sm: 'calc(var(--radius) - 4px)',
  },
  fontSize: {
    // 1rem = 16px
    /** 80px size / 84px high / bold */
    mega: ['5rem', { lineHeight: '5.25rem', fontWeight: '700' }],
    /** 56px size / 62px high / bold */
    h1: ['3.5rem', { lineHeight: '3.875rem', fontWeight: '700' }],
    /** 40px size / 48px high / bold */
    h2: ['2.5rem', { lineHeight: '3rem', fontWeight: '700' }],
    /** 32px size / 36px high / bold */
    h3: ['2rem', { lineHeight: '2.25rem', fontWeight: '700' }],
    /** 28px size / 36px high / bold */
    h4: ['1.75rem', { lineHeight: '2.25rem', fontWeight: '700' }],
    /** 24px size / 32px high / bold */
    h5: ['1.5rem', { lineHeight: '2rem', fontWeight: '700' }],
    /** 16px size / 20px high / bold */
    h6: ['1rem', { lineHeight: '1.25rem', fontWeight: '700' }],

    /** 32px size / 36px high / normal */
    'body-2xl': ['2rem', { lineHeight: '2.25rem' }],
    /** 28px size / 36px high / normal */
    'body-xl': ['1.75rem', { lineHeight: '2.25rem' }],
    /** 24px size / 32px high / normal */
    'body-lg': ['1.5rem', { lineHeight: '2rem' }],
    /** 20px size / 28px high / normal */
    'body-md': ['1.25rem', { lineHeight: '1.75rem' }],
    /** 16px size / 20px high / normal */
    'body-sm': ['1rem', { lineHeight: '1.25rem' }],
    /** 14px size / 18px high / normal */
    'body-xs': ['0.875rem', { lineHeight: '1.125rem' }],
    /** 12px size / 16px high / normal */
    'body-2xs': ['0.75rem', { lineHeight: '1rem' }],

    /** 18px size / 24px high / semibold */
    caption: ['1.125rem', { lineHeight: '1.5rem', fontWeight: '600' }],
    /** 12px size / 16px high / bold */
    button: ['0.75rem', { lineHeight: '1rem', fontWeight: '700' }],
  },
  keyframes: {
    'caret-blink': {
      '0%,70%,100%': { opacity: '1' },
      '20%,50%': { opacity: '0' },
    },
    'countdown-dash': {
      from: {
        'stroke-dashoffset': '283',
      },
      to: {
        'stroke-dashoffset': '0',
      },
    },
    'countdown-rotate': {
      from: {
        transform: 'rotate(360deg)',
      },
      to: {
        transform: 'rotate(0deg)',
      },
      '0%, 100%': {
        transformOrigin: 'center',
      },
    },
  },
  animation: {
    'caret-blink': 'caret-blink 1.25s ease-out infinite',
    'countdown-dash': 'countdown-dash 10s linear infinite',
    'countdown-rotate': 'countdown-rotate 10s linear infinite',
  },

  content: {},
} satisfies Config['theme']

export default extendedTheme;